@import "~bootstrap/scss/bootstrap";


@import "slick";
@import "slick-theme";

@import "variables";
@import "fonts";
@import "nav";
@import "modal";

body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  background: #fff;

  font-weight: 100;
  height: 100%;
}

.overflow_hidden {
  overflow-y: hidden;
}

*, ::after, ::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  z-index: 99999;

  .preloader-gif {
    display: block;
    width: 64px;
    height: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -30px 0 0 -30px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;

  section {
    padding: 40px 0px;

    @include media-breakpoint-up(md) {
      padding: 60px 0px;
    }
    @include media-breakpoint-up(lg) {
      padding: 80px 0px;
    }
    @include media-breakpoint-up(xl) {
      padding: 100px 0px;
    }

    .container {

      .row{

        .col {
          border: 1px solid white;
          border: 0 solid white !important;

          @include media-breakpoint-up(sm) {
            border: 1px solid yellow;
          }
          @include media-breakpoint-up(md) {
            border: 1px solid orange;
          }
          @include media-breakpoint-up(lg) {
            border: 1px solid green;
          }
          @include media-breakpoint-up(xl) {
            border: 1px solid red;
          }


          .home_content {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }


          .animateToTop1,
          .animateToTop2 {
            opacity: 0;
            transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
            transform: translate(0px, 50px);

            &.animate {
              opacity: 1;
              transform: translate(0px, 0px);
            }
          }

          .animateFadein {
            opacity: 0;
            transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);

            &.animate {
              opacity: 1;
            }
          }

          .animateFromLeft {
            opacity: 0;
            transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
            transform: translate(0px, 50px);

            @include media-breakpoint-up(md) {
              transform: translate(-50px, 0px);
            }

            &.animate {
              opacity: 1;
              transform: translate(0px, 0px);
            }
          }
          .animateFromCenter {
            opacity: 0;
            transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
            transform: translate(0px, 50px);

            @include media-breakpoint-up(md) {
              transform: translate(0px, 0px);
            }

            &.animate {
              opacity: 1;
              transform: translate(0px, 0px);
            }
          }
          .animateFromRight {
            opacity: 0;
            transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
            transform: translate(0px, 50px);
            @include media-breakpoint-up(md) {
              transform: translate(50px, 0px);
            }

            &.animate {
              opacity: 1;
              transform: translate(0px, 0px);
            }
          }


          .praxis_item {
            width: 80%;
            height: auto;
            background-color: white;
            padding: 30px 0px;
            margin: 0% 10%;

            @include media-breakpoint-up(md) {
              width: 100%;
              height: 100%;
              margin: 0;
            }

            .praxis_icon {
              margin: 0 auto;
              width: 78px;

              img {
                width: 100%;
              }
            }
          }

          .projekt_container {
            padding: 0;
            padding-top: 50px;

            .row {

              .col {
                position: relative;

                .projekt_images{

                  .projekt_image {
                    width: 100%;

                    img {
                      width: 100%;
                    }
                  }
                }

                .projekte_button_prev {
                  cursor: pointer;
                  position: absolute;
                  top: 50%;
                  left: 0px;
                  transform: translateY(-50%);
                  width: 60px;

                  @include media-breakpoint-up(md) {
                    left: 0px;
                  }

                  img {
                    width: 100%;
                  }
                }
                .projekte_button_next {
                  cursor: pointer;
                  position: absolute;
                  top: 50%;
                  right: 0px;
                  transform: translateY(-50%);
                  width: 60px;

                  @include media-breakpoint-up(md) {
                    right: 0px;
                  }

                  img {
                    width: 100%;
                  }
                }
              }
            }
          }

          .footer_logo {
            width:100%;
            max-width: 229px;

            img {
              width: 100%;
            }
          }

          .footer_text {
            height: auto;
            margin-top: 20px;

            a,p{
              display: block;
            }

            @include media-breakpoint-up(sm) {

            }

            @include media-breakpoint-up(md) {
              padding-top: 10px;
              margin-top: 0px;

              a,p{
                display: inline-block;
              }
            }

            @include media-breakpoint-up(lg) {
              padding-top: 15px;
            }

            @include media-breakpoint-up(xl) {
              display: flex;
              height: 60px;

              a,p{
                display: inline-block;
                align-self: flex-end;
              }
            }
          }
        }
      }
    }
  }

  #home{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;

    .home_background {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;

      background-image: url("../img/home/home_image.jpg");
      background-size: cover;
      background-position: center 0% ;
      background-position-x: 60% !important;
      background-repeat: no-repeat;

      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);


      @include media-breakpoint-up(md) {
        background-position-x: center !important;
      }
    }

    .container {
      position: relative;
      height: 100%;
      z-index: 100;

      .row {
        height: 100%;

        .col {
          height: 100%;
        }
      }
    }
  }

  #intro {
    z-index: 2;
  }

  #praxis {
    overflow: hidden;
    position: relative;

    .praxis_background {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;

      background-image: url("../img/praxis/praxis_background.jpg");
      background-size: cover;
      background-position: center 0% ;
      background-repeat: no-repeat;

      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  #projekte {
  }

  #kontakt {
    overflow-x: hidden;
    background-color: $light_gray;
  }

  #footer {
    background-color: #505050;
  }



  form {
    padding-left:  5px;

    .form-row {
      width: 100%;

      #form-messages {
        color: red !important;
      }

      .col {
        margin: 0 0 4% 0;
        padding: 0;

        .invalid-feedback {
          color: #dc3545;
          font-size: 13px;
        }

        .form-control {
          border-radius: 0;
        }

        .form-control:invalid {
          //border-color: $color_yellow !important;
        }

        .form-check-input {
          margin: 0;
          position: relative;
        }

        // check box
        .form_check_checkbox {
          margin-left:-18px;
          margin-top: 5px;
          height: auto;
          display: inline-block;
          vertical-align:top;
        }
        .form_check_copy {
          display: inline-block;
          vertical-align:top;
          width: 90%;
        }

        .form-check-label {
          padding: 0px;
          height: auto;
          color: black;
          background-color: transparent;
          border-radius: 0;
          border: 0px solid #ced4da;

          a {
            text-decoration: underline;
          }
          a:hover {
            text-decoration: none;
            color: $color-blue;
          }
        }


        label {
          padding-bottom: 0px;
          margin-left: 20px;
          text-align: left;
          display: block;
          width: 90%;
          float: left;
          margin-left:18px;
        }

        input, textarea {
          border: 0;
          border-bottom-color: currentcolor;
          border-bottom-style: none;
          border-bottom-width: 0px;
          background: transparent;
          width: 100%;
          line-height: 20px;
          font-size: 16px;
          color: #505050;
          border: 1px solid #979797;
          padding: 20px 0px 20px 20px;
          background: white;
          outline-style: none;
          box-shadow: none;

          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          font-weight: 100;
        }

        // check box
        input[type=checkbox] {
          width: auto;
          display: inline-block;
        }

        button {
          border: 1px solid red;
        }

        // button
        .submit {
          cursor: pointer;
          width: auto !important;

          padding: 10px 60px 10px 20px;
          background-color: $color-blue;
          color: white;
          text-decoration: none;
          border: 1px solid transparent;
          transition: .5s;

          .submit_icon {
            position: relative;
          }

          .a_text {
            padding-left: 10px;
          }

          &:hover {
            color: $color-blue;
            background-color: white;
            border: 1px solid $color-blue;

            .submit_icon {

              #svg_path {
                stroke: $color-blue;
              }

            }
          }

        }




        // placeholder
        ::placeholder {
          color: #aaaaaa;
          text-align: left;
        }
        ::-webkit-input-placeholder { /* Chrome */
          color: #aaaaaa;
          text-align: left;
        }
        :-ms-input-placeholder { /* IE 10+ */
          color: #aaaaaa;
          text-align: left;
        }
        ::-moz-placeholder { /* Firefox 19+ */
          color: #aaaaaa;
          opacity: 1;
          text-align: left;
        }
        :-moz-placeholder { /* Firefox 4 - 18 */
          color: #aaaaaa;
          opacity: 1;
          text-align: left;
        }

        textarea::placeholder {
          color: #aaaaaa;
        }
        textarea::-webkit-input-placeholder {
          color: #aaaaaa;
        }
        textarea:-moz-placeholder { /* Firefox 18- */
          color: #aaaaaa;
        }
        textarea::-moz-placeholder {  /* Firefox 19+ */
          color: #aaaaaa;
        }
        textarea:-ms-input-placeholder {
          color: #aaaaaa;
        }
      }
    }
  }
}


.grecaptcha-badge {
  visibility:hidden !important;

  &.show {
    visibility:visible !important;
  }
}