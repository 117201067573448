@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');


h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 34px;
  line-height: 34px;
  color: white;
  font-weight: 700;

  @include media-breakpoint-up(sm) {
    font-size: 42px;
    line-height: 42px;
  }
  @include media-breakpoint-up(md) {
    font-size: 52px;
    line-height: 52px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 64px;
    line-height: 64px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 80px;
    line-height: 80px;
  }
}
h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  line-height: 27px;
  color: black;

  &.big {
    font-size: 23px;

    @include media-breakpoint-up(sm) {
      font-size: 29px;
    }
    @include media-breakpoint-up(md) {
      font-size: 36px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 44px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 55px;
    }
  }


  @include media-breakpoint-up(sm) {
    font-size: 28px;
    line-height: 32px;
  }
  @include media-breakpoint-up(md) {
    font-size: 32px;
    line-height: 36px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 36px;
    line-height: 40px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 42px;
    line-height: 50px;
  }
}
h3 {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  line-height: 26px;
  color: $color-blue;


  @include media-breakpoint-up(sm) {
    font-size: 20px;
    line-height: 30px;
  }
  @include media-breakpoint-up(md) {
    font-size: 22px;
    line-height: 32px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 25px;
    line-height: 40px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 30px;
    line-height: 50px;
  }
}
h4 {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  line-height: 18px;
  color: $dark_gray;
  font-weight: 600;
  border-left: 7px solid white;
  padding-left: 20px;
  margin-left: 5px;


  @include media-breakpoint-up(sm) {
    font-size: 16px;
    line-height: 19px;
  }
  @include media-breakpoint-up(md) {
    font-size: 20px;
    line-height: 24px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 24px;
    line-height: 29px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 30px;
    line-height: 36px;
  }
}
h5 {
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  line-height: 13px;
  color: black;
  font-weight: 600;


  @include media-breakpoint-up(sm) {
    font-size: 13px;
    line-height: 16px;
  }
  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 19px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 20px;
    line-height: 23px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 24px;
    line-height: 28px;
  }
}


a,p,label{
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: black;

  &.bold {
    font-weight: 600;
  }

  &.blue{
    color: $color-blue;
  }
  &.white{
    color: white !important;
  }

  &.header {
    color: $dark_gray;

  }

  &.footer {
    font-size: 16px;
    line-height: 20px;
    color: white;
    margin-bottom: 0;
  }

  @include media-breakpoint-up(sm) {
    font-size: 14px;
    line-height: 21px;
  }
  @include media-breakpoint-up(md) {
    font-size: 15px;
    line-height: 23px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 15px;
    line-height: 23px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 18px;
    line-height: 28px;
  }


}

ul{

  li{
    list-style: none;

    p {
      margin-bottom: 0;
    }
  }
}

.button_holder {

  .button {
    padding: 10px 10px;
    background-color: $color-blue;
    color: white;
    text-decoration: none;
    border: 1px solid transparent;
    transition: .5s;

    @include media-breakpoint-up(sm) {
      padding: 10px 10px;
    }
    @include media-breakpoint-up(md) {
      padding: 10px 10px;
    }
    @include media-breakpoint-up(lg) {
      padding: 10px 20px;
    }

    &:hover {
      color: $color-blue;
      background-color: white;
      border: 1px solid $color-blue;
    }
  }

  .button_insta {

    img {
      width: 41px;
    }
  }
  .button_deactive {
    opacity: .3;
    cursor: default;

    &:hover {

    }
  }

}