.navbar {
  width: 100%;
  position: fixed;

  background-color: rgba(0, 0, 0, .7);
  z-index: 901;
  padding: 0;
  transition: .5s;
  min-height: 60px;

  .container{

    @include media-breakpoint-up(md) {
      position: relative;
    }

    .navbar-brand {
      padding-top: 0;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 122px;
      transition: .5s;

      img {
        width: 100%;
      }

      @include media-breakpoint-up(md) {
        width: 182px;
      }
    }

    .navbar-toggler {
      /*position: absolute;
      top: 10px;
      right: 0;
      border: 0px;

      @include media-breakpoint-up(md) {
        top: 0px;
      }*/
    }

    .navbar-toggler {
      border-radius: 0;
      background-color: transparent;
      border: none;
      height: 40px;
      position: absolute;
      top: 10px;
      right: 0;
      z-index: 999;


      // /* remove the blue outline when active or focused */
      &:active, &:focus {
        outline: 0;
      }

      /* basic styles for each icon bar */
      .icon-bar {
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;
        margin: 4px 0 4px 0;
        transition: all 0.2s;
        background-color: white;

        /* custom .navbar-dark .icon-bar background */
        .navbar-dark & {
          background: #ffffff;
        }

        /* .navbar open top .icon-bar rotated down 45°*/
        &:nth-of-type(1) {
          transform: rotate(45deg);
          transform-origin: 10% 10%;
        }

        /* .navbar open middle .icon-bar invisible*/
        &:nth-of-type(2) {
          opacity: 0;
          filter: alpha(opacity=0);
        }

        /* .navbar open bottom .icon-bar rotated up 45°*/
        &:nth-of-type(3) {
          transform: rotate(-45deg);
          transform-origin: 10% 90%;
        }
      }

      /* styles for when .navbar is closed */
      &.collapsed {

        .icon-bar {

          /* .navbar closed top .icon-bar no rotation - straight */
          &:nth-of-type(1) {
            transform: rotate(0);
          }

          /* .navbar open middle .icon-bar visible */
          &:nth-of-type(2) {
            opacity: 1;
            filter: alpha(opacity=100);
          }

          /* .navbar open bottom .icon-bar no rotation - straight */
          &:nth-of-type(3) {
            transform: rotate(0);
          }
        }
      }
    }

    #main_nav {
      padding-top: 46px;
      transition: .5s;

      ul {
        padding-top: 20px;
        padding-bottom: 20px;

        @include media-breakpoint-up(md) {
          padding-top: 0px;
          padding-bottom: 0px;
        }

        li {
          padding-left: 36px;

          &.language {
           display: inline-block;
          }

          a,p {
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            line-height: 30px;
            font-weight: 500;
            color: white;
            padding: 0px;
            border-bottom: 4px solid transparent;
            display: inline-block;
            margin-bottom: 10px;

            @include media-breakpoint-up(md) {
              display: block;
              margin-bottom: 0px;

              &.language {
                display: inline-block;
              }
            }

            .underline,
            &:hover,
            &.active {
              border-bottom: 4px solid white;
            }
          }
          p {

            &.underline {
              border-bottom: 4px solid white;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    background-color: transparent;
    min-height: auto;
  }

  &.scrolled {
    background-color: rgba(0, 0, 0, .7);

    .container{

      .navbar-brand {
        width: 122px;
      }

      #main_nav {
        //padding-top: 0px;

        @include media-breakpoint-up(md) {
          padding-top: 26px;
        }
      }
    }
  }
}