.modal {
  padding-right: 0 !important;
  width: 100%;

  // modal close
  .close {
    z-index: 1000;
    position: fixed;
    //top: 55px;
    //right: 50px;
    top: 10px;
    right: 0;
    font-size: 26px;
    width: 30px;
    opacity: 1;

    .close_button {
      z-index: 996;
      display: block;
      cursor: pointer;
      position: absolute;
      width: 30px;
      height: 30px;
      top: 0px;
      right: 0;
      padding: 0;

      span {
        display: block;
        position: relative;
        width: 100%;
        height: 2px;
        margin-bottom: 5px;
        -webkit-transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
        -moz-transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
        -ms-transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
        -o-transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
        transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
        fill: white;
        background-color: white;
        //margin-top: 10px;


      }

      &:hover {

        span:nth-child(1) {
          -webkit-transform: translate(0, 15px) rotate(225deg);
          -ms-transform: translate(0, 15px) rotate(225deg);
          -o-transform: translate(0, 15px) rotate(225deg);
          transform: translate(0, 15px) rotate(225deg);
          fill: white;
          background-color: white;
          opacity: 1;
        }

        span:nth-child(2) {
          -webkit-transform: translate(0, 8px) rotate(135deg);
          -ms-transform: translate(0, 8px) rotate(135deg);
          -o-transform: translate(0, 8px) rotate(135deg);
          transform: translate(0, 8px) rotate(135deg);
          fill: white;
          background-color: white;
          opacity: 1;
        }
      }

      span:nth-child(1) {
        -webkit-transform: translate(0, 15px) rotate(135deg);
        -ms-transform: translate(0, 15px) rotate(135deg);
        -o-transform: translate(0, 15px) rotate(135deg);
        transform: translate(0, 15px) rotate(135deg);
      }
      span:nth-child(2) {
        -webkit-transform: translate(0, 8px) rotate(45deg);
        -ms-transform: translate(0, 8px) rotate(45deg);
        -o-transform: translate(0, 8px) rotate(45deg);
        transform: translate(0, 8px) rotate(45deg);
      }
    }

    &.blue {

      .close_button {

        span {
          fill: white;
          background-color: white;
        }

        &:hover {

          span:nth-child(1) {
            fill: white;
            background-color: white;
          }
          span:nth-child(2) {
            fill: white;
            background-color: white;
          }
        }
      }
    }
  }

  .modal-full {
    min-width: 100%;
    margin: 0;

    .modal-content {
      min-height: 100vh;
      background-color: $color-blue;

      .container,
      .container-fluid{
        //padding-top: 23px;

        @include media-breakpoint-up(md) {
          padding-top: 30px;
        }

        .row {
          margin-left: 0;
          margin-right: 0;

          .col {
            padding-right: 0;
            padding-left: 0;

            border: 1px solid white;
            border: 0 solid white !important;

            @include media-breakpoint-up(sm) {
              border: 1px solid yellow;
            }
            @include media-breakpoint-up(md) {
              border: 1px solid orange;
            }
            @include media-breakpoint-up(lg) {
              border: 1px solid green;
            }
            @include media-breakpoint-up(xl) {
              border: 1px solid red;
            }

            .logo {
              margin: 0 auto;
              width: 50px;

              img {
                width: 100%;
              }
            }

            h2 {
              padding-top: 30px;
              padding-bottom: 10px;
            }

            ul{
              padding-left: 14px;

              li{
                list-style: disc;
                color: white;
                padding-bottom: 0;
              }

              &.privacy {
                padding-top: 10px;
                padding-bottom: 10px;
                list-style: disc;
                color: white;

                li {
                  display:inline;

                  @include media-breakpoint-up(md) {
                    padding-bottom: 0px;
                  }
                }

              }
            }

            .modal_holder_left {
              min-height: 100px;

              @include media-breakpoint-up(md) {
                min-height: 550px;
              }
            }
            .modal_holder_right {
              position: relative;
              top: 0;
              left: 0;
              text-align: center;

              img {
                width: 50%;
              }

              @include media-breakpoint-up(md) {
                position: absolute;
                top: 95px;
                left: 20px;

                img {
                  width: 300px;
                }
              }
              @include media-breakpoint-up(lg) {
                position: absolute;
                top: 95px;
                left: 100px;

                img {
                  width: 400px;
                }
              }
              @include media-breakpoint-up(xl) {
                position: absolute;
                top: 95px;
                left: 153px;

                img {
                  width: 480px;
                }
              }
            }

            .button_holder {
              z-index: 1000;
              padding-top: 10px;

              @include media-breakpoint-up(md) {
                padding-top: 40px;
              }
            }

            .client_logo {
              width: 100%;
              height: 80px;
              padding: 20px;
              /* border: 1px solid orange;*/


              @include media-breakpoint-up(sm) {
                height: 80px;
                padding: 15px;
              }
              @include media-breakpoint-up(md) {
                height: 100px;
                padding: 20px;
              }
              @include media-breakpoint-up(lg) {
                height: 120px;
                padding: 30px;
              }
              @include media-breakpoint-up(xl) {
                padding: 20px;
              }

              img {
                max-height: 100%;
                width: 100%;

                position: relative;
                top: 50%;
                transform: translateY(-50%);
              }
            }

            .inxmail_logo {
              height: 70px;

              img {
                height: 100%;
              }
            }

            .lead_bg_teaser {
              width: 100%;
              /*height: 70px;*/
              border: 3px solid orange;
              text-align: center;
              /*padding: 8px 20px;*/
              padding: 7px 20px;

              a {
                text-align: left;
              }

              img {
                width: 100%;
              }

              @include media-breakpoint-up(sm) {
                width: 350px;
              }
            }

            .speed_card {
              padding: 1rem;
              background-color: orange;
              opacity: 1;
              color: $white;
              border-radius: 0;
              height: 100%; ;
              /*margin: 0 0px;*/

              @include media-breakpoint-up(sm) {
                /* margin: 0 20px;*/
              }

              ul {
                padding-left: 1.2rem;
                list-style: disc;

                li {
                  color: $white;
                  /* line-height: 1.7rem;*/
                  height: auto;
                }
              }
            }

            .contact_reply_icon {
              width: 140px;
              margin: 0 auto;

              img {
                width: 100%;
              }

              @include media-breakpoint-up(sm) {
                width: 220px;
              }
            }
          }
        }

        .speed_icon {
          position: absolute;
          left: -40px;
          bottom: 0;
          width: 480px;
          display: none;

          img {

            width: 100%;
          }

          @include media-breakpoint-up(xl) {
            display: block;
          }
        }
      }

    }
  }
}